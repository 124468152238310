import React, { useContext, useEffect, useState, useMemo } from "react"
import { useHttpGet, useRouteQuery } from "../../../core/hooks"
import PageHeaderWrapper from "../../PageHeaderWrapper"
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import { Table, Steps } from "antd"
import { useActionExecutor } from "../../../core/actionExecuter"
import { renderActions, renderItemFieldValue } from "../../../core/domUtil"
import Title from "antd/lib/typography/Title"
import { conditionsMatched } from "../../../core/conditionsMatcher"
const { Step } = Steps;
export default (props) => {
    const {uri, query, fixedQuery} = props
    const [, actionResult, actionExecutor] = useActionExecutor()
    const [response, requestProfile] = useHttpGet(uri)
    useEffect(() => {
        requestProfile({...query, ...fixedQuery})
    }, [query, requestProfile, actionResult])

    return <LoadingResponseWrapper
        {...response}
        refresh={requestProfile}
        renderContent={({tables: displayTables, info = []} = {}) => {
            return <div className="page-content-wrapper">
                {
                    info.map(({label, money}, key) => {
                        return <Label key={'text' + key} label={label}>{money}</Label>
                    })
                }

                {displayTables.map(({ displayFields = [], props = {}, sum_key_name, items, title, _actions_=[]}, idxi) => {
                    return <div style={{ marginTop: 20 }}>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <Title level={4}>{title}</Title>
                            <div>
                                {renderActions(_actions_, actionExecutor)}
                            </div>
                        </div>
                        
                        
                        <ItemsTable columns={displayFields.filter((df, i) => {
                            return  (!df.show_condition || !df.show_condition.length || (props.fixedState && conditionsMatched(df.show_condition, props.fixedState)))
                        }) || []} sum_key_name={sum_key_name} size={"small"} props={props} items={items || []} />
                    </div>
                })}
            </div>
            // const [display] = displays;
            // return <div className="page-content-wrapper">
            //     <DisplayComponent {...display} />

            // </div>
        }}
    />
}

const ItemsTable = ({ columns = [], items = [], sum_key_name, footer, size}) => {

    const totalMoney = sum_key_name ? items.reduce((money, item) => {
        return money + (item?._raws_?.change_status == 1 ? 0 : parseFloat(item[sum_key_name]))
    }, 0): 0;

    // const beforeSumKeyName = columns && columns.length ? columns[0].key : undefined
    const beforeSumKeyName = columns.reduce((last, field, i, array) => {
        if (last) return last;
        if (field.key === sum_key_name) {
            return array[i - 1].key
        }
    }, undefined)

    return <Table 
        dataSource={sum_key_name ? [...items, { [sum_key_name]: totalMoney, [beforeSumKeyName]: '合计：' }]: items}
        size={size}
        bordered={true}
        pagination={false}
        footer={footer}
        rowKey={({id}) => id}>
        {columns.map((field) => {
            const {title, key,} = field
            return <Table.Column title={title} dataIndex={key} render={(cellVal, record, index) => {
                if (index === items.length) {
                    if (beforeSumKeyName === key) {
                        return cellVal
                    }
                    if (sum_key_name !== key) {
                        return ''
                    }
                }
                return renderItemFieldValue(field, record, false)
            }}>
            </Table.Column>
        })}
    </Table>
}

const Label = ({label, children}) => {
    return <span style={{marginRight: '30px', fontSize: 20}}>{label}：&nbsp;{children}</span>
}