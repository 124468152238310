import { Col, Input, InputNumber, Row, Table } from "antd"
import Title from "antd/lib/typography/Title"
import { FormatMoney } from "format-money-js"
import React, { useEffect, useState } from "react"
import { renderItemFieldValue } from "../../../core/domUtil"
import { useHttpGet } from "../../../core/hooks"


export default ({ props: { key, label }, payment_id, valueSet = {}, rows, valueSetUpdater, onChange, ...other }) => {
    const { supplier_id, agent_supplier_id, statement_id, statement_total_price, pay_currency: currency = 1 } = valueSet
    const [useOrderPrepay, setOrderPrepay] = useState({})
    const [errorValue,setErrorValue] = useState()
    const [response, requestProfile, clear] = useHttpGet('/production/getPrePaidSupplier')
    useEffect(() => {
        if ((agent_supplier_id || supplier_id) && currency) {
            requestProfile({
                supplier_id: agent_supplier_id || supplier_id || 0,
                statement_id: statement_id || 0,
                currency: currency,
            })
        } else { clear() }
    }, [agent_supplier_id, supplier_id, currency])

    const symbol = currency > 1 ? '$' : '￥'
    const { loading, data: items = [], success } = response

    useEffect(() => {
        let prepays = {}
        items.forEach((v, i) => {
            prepays[v.id] = v.use_amount_cny
        })
        setOrderPrepay(prepays)
    }, [response])


    useEffect(() => {
        onChange(JSON.stringify(useOrderPrepay))
    }, [useOrderPrepay])
    
    const usePrepayTotal = Object.keys(useOrderPrepay).reduce((p, k, i) => {
        return p + useOrderPrepay[k]
    }, 0)

    const columns = [
        {
            title: '订单编号',
            key: 'purchase_order_id_code',
            style: 'link',
        }, {
            title: '付款单',
            key: 'payment_id_code',
            style: 'link',
        }, {
            title: '总计预付',
            key: 'amount_cny',
            style: 'currency',
        }, {
            title: '已核销金额',
            key: 'hexiao_total_cny',
            style: 'currency',
        }, {
            title: '待核销金额',
            key: 'available_total_cny',
            style: 'currency',
        }, {
            title: '核销金额',
            key: 'use_amount_cny',
            render: (value, record, index) => {
                return <div><InputNumber
                    defaultValue={value}
                    value={useOrderPrepay[record.id]}
                    style={{border:errorValue?'#ff4d4f 1px solid':''}}
                    max={Math.min(parseFloat(record.available_total_cny), parseFloat(statement_total_price))}
                    min={0}
                    onChange={(v) => {
                        let error = v > record.available_total_cny || v > statement_total_price
                        setErrorValue(error)
                        setOrderPrepay({
                            ...useOrderPrepay,
                            [record.id]: parseFloat(v) || 0,
                        })
                    }}
                ></InputNumber></div>
            },
        }
    ]
    // if ((!agent_supplier_id && !supplier_id)) {
    //     return <span>请选择供应商</span>
    // }
    // if (!loading && ((!items || items.length == 0))) {
    //     return <span>该供应商暂无待核销的预付款</span>
    // }
    const fm = new FormatMoney();
    return <div style={{marginTop:15,marginBottom:15}}>
        <Title level={5}><span style={{color:'#ff4d4f',marginRight:6}}>*</span>预付核销: </Title>
        <Table
            title={() =>''}
            pagination={false}
            loading={loading}
            size={'small'}
            dataSource={items}
            locale={{emptyText:'该供应商暂无待核销的预付款'}}
            rowKey={({ id }) => id}>
            {columns.map((field) => {
                const { title, key, render } = field
                return <Table.Column title={title} dataIndex={key} render={
                    render ? render : (value, record, index) => {
                        return renderItemFieldValue(field, record)
                    }}>
                </Table.Column>
            })}
        </Table>
        <Row style={{margin: 20}}>
            <Col span={2}>
                <Title level={5}><span style={{color:'#ff4d4f',marginRight:6}}>*</span>优惠或减扣: </Title>
            </Col>
            <Col span={5}>
                <Input
                    type='number'
                    addonBefore={currency == 2 ? '$' : '￥'}
                    // prefix={currency == 2 ? '$' : '￥'}
                    style={{ width: '100%' }}
                    size={'large'}
                    min={0}
                    placeholder="优惠金额"
                    value={valueSet['discount_amount']}
                    onChange={(event) => {
                        let v = event.nativeEvent.target.value
                        valueSetUpdater({
                            discount_amount: v
                        })
                }}/>
            </Col>
            <Col span={5}>
                <Input
                    style={{ width: '100%' }}
                    size={'large'}
                    value={valueSet['discount_reason']}
                    placeholder="优惠原因"
                    onChange={(event) => {
                        let v = event.nativeEvent.target.value
                        valueSetUpdater({
                            discount_reason: v
                        })
                }}/>
            </Col>
        </Row>


        {
            statement_total_price && <div style={{marginTop: 30,marginBottom:20}}>
                <div className={'paymentInfoSummaryItem'}>
                    <div>{'对账金额'}: {fm.from(+ statement_total_price, { symbol: symbol, decimals: 2})}</div>
                </div>
                <div className={'paymentInfoSummaryItem'}>
                    <div>{'核销总额'}: <span style={{ color: 'orange' }}>-{fm.from(+ usePrepayTotal, { symbol: symbol, decimals: 2})} </span></div>
                </div>
                {
                valueSet['discount_amount'] && <div className={'paymentInfoSummaryItem'}>
                    <div>{'减扣金额'}: <span style={{ color: 'orange' }}>-{fm.from(parseFloat(valueSet['discount_amount']) || 0, { symbol: symbol, decimals: 2})} </span></div>
                </div>
                }
                <div className={'paymentInfoSummaryItem'}>
                    <div>{'应付金额'}:  <span style={{ color: 'red' }}>{fm.from(Math.max(0, statement_total_price - usePrepayTotal - (parseFloat(valueSet['discount_amount']) || 0)), { symbol: symbol, decimals: 2})}</span></div>
                </div>
            </div> || ''
        }
    </div>
}
